import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from './components/layout'
import Head from './components/head'

const Privacy = () => {
  const data = useStaticQuery(graphql`
  query privacyPageData {
    privacyPage: markdownRemark(frontmatter: {templateKey: {eq: "privacy-policy"}}) {
      html
      frontmatter {
        title
        }
    }
  }
  `)
  console.log(data)
  return (
    <Layout>
      <Head title="Privacy Policy" />
      <div className="text-center"><h1>{data.privacyPage.frontmatter.title}</h1></div>
      <hr />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: data.privacyPage.html}}></div>
      </div>
    </Layout>
  )
}

export default Privacy