import React from 'react'
import { Link } from 'gatsby'
import footerStyles from './footer.module.scss'
import { FaFacebook, FaTwitter } from 'react-icons/fa';

const currentDate= new Date();
const Footer = () => {
  return(
    <footer>
      <div className={`container ${footerStyles.wrapper}`}>
        <span>© Stuart Doughty {currentDate.getFullYear()}</span>
        <div className={`${footerStyles.linksWrapper}`}>
          <a className={footerStyles.socialIcon} href='https://www.facebook.com/Stuart-Doughty-112030457208728' title='Facebook'>
            <FaFacebook alt="Facebook Icon" />
          </a>
          <a className={footerStyles.socialIcon} href='https://twitter.com/StuartDoughty9' title='twitter'>
            <FaTwitter alt="Twitter Icon" />
          </a>
        <Link to='/privacy'>Privacy Policy</Link> | <Link to='/contact'>Contact</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer